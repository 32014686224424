<template>
  <CCardBody>

    <alert-section
      :successAlertMessage="successAlertMessage"
      :dismissSecs="dismissSecs"
      :dismissSuccessAlert="dismissSuccessAlert"
      :errorAlertMessage="errorAlertMessage"
      :showErrorAlert="showErrorAlert"
    />

    <!-- Filters -->
    <CCard class="filters" accent-color="warning">
      <CCardHeader
        role="button"
        class="text-warning shadow-none card-header"
        @click="resetFilters"
      >
        <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2">
          <div class="d-flex flex-md-row flex-column" style="gap: 0.5rem;">

            <!-- Invoice Filter -->
            <div class="flex-grow-1">
              <CMultiSelect
                selectionType="tags"
                :multiple="false"
                :selection="true"
                :selected.sync="selectedInvoiceFilter"
                @update="invoiceFilter"
              >
                <option value="all">All</option>
                <option value="paid">Paid</option>
                <option value="unpaid">Unpaid</option>
                <option value="mailed">Mailed</option>
                <option value="not-mailed">Not Mailed</option>
                <option value="transfered">Transfered</option>
                <option value="not-transfered">Not Transfered</option>
              </CMultiSelect>
            </div>

            <!-- Company Filter -->
            <div class="flex-grow-1">
              <v-select
                class="v-select-filter"
                placeholder="Select company.."
                v-model="selectedCompany"
                :options="allCompanies"
                @input="companyFilter"
              />
            </div>

            <!-- Pagination -->
            <div v-if="pages > 1">
              <CPagination
                align="end"
                :dots='false'
                :pages="pages"
                :active-page.sync="activePage"
              />
            </div>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <CDataTable
      striped
      hover
      :items="loadedItems"
      :fields="fields"
      :sorter="{ external: true, resetable: true }"
      :table-filter="{ external: true, lazy: true, placeholder: '...' }"
      :tableFilterValue.sync="search"
      :column-filter="{ external: true, lazy: true }"
      :itemsPerPageSelect="{
        external: true,
        values: [5, 15, 25, 50, 100, 150, 200, 250, 500],
      }"
      :items-per-page.sync="itemsPerPage"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No MenutePay invoices found!',
      }"
      @update:sorter-value="sorterValue"
      @update:table-filter-value="tableSearch"
      @pagination-change="paginationChange"
    >

      <!-- ID -->
      <template #id="{item}">
        <td>
          #{{ item.id }}
        </td>
      </template>

      <!-- Sent  -->
      <template #ivMailed="{item}">
        <td class="text-center">
          <CIcon
            :height="32"
            :class="`text-${item.ivMailed ? 'success' : 'danger'}`"
            :name="`cil-${item.ivMailed ? 'check-alt' : 'x'}`"
          />
        </td>
      </template>

      <!-- Price -->
      <template #ivTotIncl="{item}">
        <td>
          {{ item.ivTotIncl | toCurrency('remove_thousand_separator') }}
        </td>
      </template>

      <!-- Balance -->
      <template #balance="{item}">
        <td>
          <strong :class="`text-${(item.total_payment - item.ivTotIncl) >= 0 ? 'success' : 'danger'}`">
            {{ (item.total_payment - item.ivTotIncl) | toCurrency('signDisplay') }}
          </strong>
        </td>
      </template>

      <!-- Invoice Number -->
      <template #ivNr="{item}">
        <td>
          <CLink @click="viewInvoice(item)">
            {{ item.ivNr }}
          </CLink>
        </td>
      </template>

      <!-- Date (ivDate) Filter -->
      <template #ivDate-filter>

        <div class="input-group mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text p-1 w-min">From</span>
          </div>
          <input
            id="date-filter-from"
            type="date"
            v-model="filters.invoices.startDate"
            class="w-min"
          />
        </div>

        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text p-1 w-min">To</span>
          </div>
          <input
            id="date-filter-from"
            type="date"
            v-model="filters.invoices.endDate"
            class="w-min"
          />
        </div>
      </template>

      <!-- Date (ivDate) -->
      <template #ivDate="{item}">
        <td :class="isToday(item.ivDate) ? 'text-danger font-weight-bold' : ''">
          {{ item.ivDate }}
        </td>
      </template>

      <!-- Company -->
      <template #company="{item}">
        <td>
          #{{ item.company?item.company.id:null }}
          <CLink class="font-weight-bold" target="_blank">
            {{ item.company ? item.company.name : null }}
          </CLink>
        </td>
      </template>

      <!-- Actions -->
      <template #actions="{item}">
        <td class="py-2 text-center">
          <CButton
            size="sm"
            color="primary"
            shape="pill"
            v-c-tooltip="{
              html: true,
              content: 'Download'
            }"
            @click="viewInvoice(item)"
          >
            <CIcon name="cil-cloud-download" />
          </CButton>

          <CButton
            size="sm"
            color="info"
            shape="pill"
            class="ml-1"
            v-c-tooltip="{
              html: true,
              content: 'Send E-Mail'
            }"
            @click="sendInvoice(item)"
          >
            <CIcon name="cil-send" />
          </CButton>

          <CButton
            size="sm"
            :color="item.is_paid ? 'success' : 'warning'"
            shape="pill"
            class="ml-1"
            v-c-tooltip="{
              html: true,
              content: 'Payments'
            }"
            @click="showPayments(item)"
          >
            <CIcon name="cil-euro" />
          </CButton>
        </td>
      </template>
    </CDataTable>

    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>

          <div class="action">
            <CButton
              size="sm"
              color="success"
              @click="makeInvoicesModal = true"
            >
              <CIcon name="cil-send" /> Create Invoices
            </CButton>
          </div>

          <div class="action">
            <CButton
              size="sm"
              color="primary"
              v-c-tooltip="{
                html: true,
                content: `Download All Filtered Invoices`
              }"
              @click="downloadZip"
              :disabled="total == 0"
            >
              <CIcon name="cil-cloud-download" /> Download All
            </CButton>
          </div>

          <div class="action">
            <CButton
              size="sm"
              color="info"
              @click="transferInvoice"
              shape="pill"
              variant="ghost"
            >
              <CIcon name="cil-data-transfer-up" /> Transfer All Untransferred Invoices
            </CButton>
          </div>

          <div v-if="selectedInvoiceFilter == 'not-mailed'">
            <CButton
              size="sm"
              color="info"
              @click="sendAllUnmailedInvoices"
              shape="pill"
              variant="ghost"
            >
              <CIcon name="cil-send" /> Send All Unmailed Invoices by Email
            </CButton>
          </div>

          <div v-if="pages > 1" class="ml-auto">
            <CPagination
              align="center"
              :dots='false'
              :pages="pages"
              :active-page.sync="activePage"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>

    <CModal class="make-invoices" title="Create Invoices" size="sm" color="success" centered :show.sync="makeInvoicesModal">
      <CInput
        type="month"
        v-model="selectedMonth"
        :max="prevMonth"
      />

      <template #footer class="p-1">
        <CButton @click="makeInvoicesModal = false" color="link">Cancel</CButton>
        <CButton @click="makeInvoices" :disabled="!selectedMonth" size="sm" color="success">
          <CIcon name="cil-send" /> Create
        </CButton>
      </template>
    </CModal>

    <payments-aside :invoice.sync="selectedInvoice" />

    <mc-spinner :opacity="0.8" v-show="submitted" :mtop="(32 + mcSpinnerMarginTop) + 'px'" />
  </CCardBody>
</template>

<script>
import { mapState } from 'vuex'
import PaymentsAside from "../asides/Payments";
import { DatePicker } from 'v-calendar'
import _debounce from 'lodash/debounce';

export default {
  name: "BackendTable",
  components: {
    PaymentsAside,
    'v-date-picker': DatePicker,
  },
  data() {
    return {
      loadedItems: [],
      selectedInvoice: null,
      fields: [
        { key: "actions",       label: "Actions",     filter: false, sorter: false, _style: "min-width: 140px; width: 140px; text-align: center;" },
        { key: "id",            label: "ID",          filter: false, _style: "min-width: 50px; width: 50px;" },
        { key: "ivMailed",      label: "Sent",        filter: false, _style: "min-width: 50px; width: 50px; text-align: center;" },
        { key: "ivNr",          label: "Inv.Number",  filter: false, _style: "min-width: 100px; width: 120px;" },

        { key: "ivTotIncl",     label: "Amount",      filter: false, _style: "min-width: 100px; width: 120px;" },
        { key: "balance",       label: "Balance",     filter: false, _style: "min-width: 100px; width: 120px;" },
        { key: "ivDate",        label: "Date",        filter: true, _style: "min-width: 160px; width: 160px;" },
        { key: "ivName",        label: "Inv.Name",    filter: false, _style: "min-width: 180px;" },

        { key: "company",       label: "Company",     filter: false, _style: "min-width: 200px;", sorter: false },
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      asc: null,
      search: null,
      filteredColumn: null,

      loading: false,
      submitted: false,

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      // Invoice Filter işlemleri için
      selectedInvoiceFilter:['unpaid'],

      // Restaurants Filter işlemleri için
      allCompanies: [],

      // Element Cover Spinner
      mcSpinnerMarginTop: 0,

      // Make Invoices
      makeInvoicesModal: false,
      selectedMonth: null,
    };
  },

  async beforeMount() {
    this.filterByDate = _debounce(async () => {
      await this.getAllItems();
    }, 1000);
  },

  async mounted() {
    this.activePage =  this.queryPage;
    await this.getAllCompanies();
    await this.getAllItems();

    this.selectedMonth = this.prevMonth;

    window.onscroll = () => {
      this.mcSpinnerMarginTop = document.documentElement.scrollTop;
    }
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage) {
        this.$router.push({ name: this.$route.name, query: { page: this.activePage } });
      }
      this.onTableChange();
    },
    dateFilter() {
      this.filterByDate();
    },

    "selectedInvoice.isPaymentAdded": {
      handler: function (val) {
        val && this.getAllItems()
      },
      deep: true,
    },
  },

  computed: {
    ...mapState(['filters']),

    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage] },
    queryPage() { return parseInt(this.$route.query.page) || 1 },
    dateFilter() { return [this.filters.invoices.startDate, this.filters.invoices.endDate] },
    prevMonth() { return this.moment(new Date()).subtract(1,'months').format('YYYY-MM') },
    selectedCompany: {
      get() { return this.$store.state.filters.menutepay.selectedCompany },
      set(value) { this.$store.state.filters.menutepay.selectedCompany = value },
    }
  },

  methods: {
    async getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.MENUTEPAY.INVOICES.GET_ALL),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          search: this.search,
          orderBy: this.orderBy,
          asc: this.asc,
          invoiceFilter: this.selectedInvoiceFilter,
          company: (this.selectedCompany && this.selectedCompany.value) ? this.selectedCompany.value : null,
          date_from: this.filters.invoices.startDate,
          date_to: this.filters.invoices.endDate
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.loadedItems = items.data;
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async getAllCompanies() {
      await this.$axios
        .get(this.$backend.MENUTEPAY.COMPANIES.GET_LIST)
        .then((response) => {
          this.allCompanies = response.data;
        })
        .catch(() => { this.allCompanies = [] })
    },

    async viewInvoice(item) {
      this.submitted = true;

      await this.$axios
        .get(this.$backend.MENUTEPAY.INVOICES.VIEW.replace("{id}", item.id), { responseType: 'blob' })
        .then((response) => {
          const fileName = `MenutePay_Factuur_${item.ivNr}.pdf`;

          // Download file from Blob
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
          // <a href="blob:http://localhost:8080/f11c5655-6941-40b3-97bd-18152eb2a13b" download="Factuur_2022_15.pdf"></a>
        })
        .finally(() =>this.submitted = false);
    },

    async downloadZip() {
      if (this.selectedCompany === null) {
        this.$toast.warning('Please select a company to download all invoices!');
        return;
      }
      if (!this.filters.invoices.startDate) {
        this.$toast.warning('Please select a start date to download all invoices!');
        return;
      }

      this.submitted = true;

      var url = new URL(this.$backend.MENUTEPAY.INVOICES.DOWNLOAD_ALL),
        params = {
          company: (this.selectedCompany && this.selectedCompany.value) ? this.selectedCompany.value : null,
          invoiceFilter: this.selectedInvoiceFilter,
          date_from: this.filters.invoices.startDate,
          date_to: this.filters.invoices.endDate,
          search: this.search,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios
        .get(url, { responseType: 'blob' })
        .then(({ data }) => {
          const fileName = `MenutePay_Facturen.zip`;

          // Download file from Blob
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .finally(() =>this.submitted = false);
    },

    async sendInvoice(item) {
      this.submitted = true;

      await this.$axios
        .get(this.$backend.MENUTEPAY.INVOICES.SEND.replace("{id}", item.id))
        .then((response) => {
          item.ivMailed = true;

          this.showErrorAlert = false;
          this.successAlertMessage = {itemName: 'Success:', message: response.data.message};
          this.dismissSuccessAlert = this.dismissSecs;
        })
        .catch((error) => {
          this.errorAlertMessage = error.response.data;
          this.showErrorAlert = true;
        })
        .finally(() => {
          window.scrollTo(0,0);
          this.submitted = false;
        });
    },

    async sendAllUnmailedInvoices(){
      this.submitted = true;

      await this.$axios
        .post(this.$backend.MENUTEPAY.INVOICES.SEND_ALL_UNMAILED)
        .then((response) => {
          this.showErrorAlert = false;
          this.successAlertMessage = {itemName: 'Success:', message: response.data};
          this.dismissSuccessAlert = this.dismissSecs;
        })
        .catch((error) => {
          this.errorAlertMessage = error.response.data;
          this.showErrorAlert = true;
        })
        .finally(() => {
          window.scrollTo(0,0);
          this.submitted = false;
        });
    },

    async transferInvoice() {
      this.submitted = true;

      await this.$axios
        .post(this.$backend.INVOICES.TRANSFER)
        .then((response) => {
          this.getAllItems();
          this.showErrorAlert = false;
          this.successAlertMessage = {itemName: 'Success:', message: response.data.message};
          this.dismissSuccessAlert = this.dismissSecs;
        })
        .catch((error) => {
          this.errorAlertMessage = error.response.data;
          this.showErrorAlert = true;
        })
        .finally(() => {
          window.scrollTo(0,0);
          this.submitted = false;
        });
    },

    async makeInvoices() {
      this.makeInvoicesModal = false;
      this.submitted = true;

      await this.$axios
        .post(this.$backend.MENUTEPAY.INVOICES.MAKE, {
          date: this.selectedMonth
        })
        .then((response) => {
          this.getAllItems();
          this.showErrorAlert = false;
          this.successAlertMessage = {itemName: 'Success:', message: response.data.message};
          this.dismissSuccessAlert = this.dismissSecs;
        })
        .catch((error) => {
          this.errorAlertMessage = error.response.data;
          this.showErrorAlert = true;
        })
        .finally(() => {
          window.scrollTo(0,0);
          this.submitted = false;
          this.selectedMonth = this.prevMonth;
        });
    },

    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllItems();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.asc = item.column ? item.asc : null; //column null ise asc=null olsun
      this.getAllItems();
    },
    tableSearch(search) {
      this.getAllItems();
    },

    // Filters
    invoiceFilter(value) {
      this.selectedInvoiceFilter = value;
      this.getAllItems();
    },
    companyFilter(value) {
      this.getAllItems();
    },
    resetFilters() {
      if (this.$store.state.filterShow) {
        this.selectedCompany = null;
        this.getAllItems();
      }
      this.$store.commit('toggle', 'filterShow');
    },

    // Show Invoice Payments
    showPayments(invoice) {
      this.selectedInvoice = {
        ...invoice,
        isPaymentAdded: false
      };
      this.$store.commit("toggle", "invoicePaymentsAsideShow");
    },

    // Helper functions
    isToday(dateString) {
      const someDate = new Date(dateString);
      const today = new Date()
      return someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
    },
  },
};
</script>

<style>
.table th, .table td {
  padding: 0.5rem 0.25rem;
  vertical-align: middle;
}
@media (min-width: 576px) {
  input[aria-label='table filter input'] {
    width: 280px !important;
  }
}

.make-invoices .modal-header {
  padding: 0.75rem !important;
}
.make-invoices .modal-footer {
  padding: 0.25rem !important;
}

.is-paid .c-switch-input:disabled ~ .c-switch-slider {
  opacity: 1;
}
</style>

<style scoped>
/* Date Filter */
span.w-min {
  width: 48px;
}
input.w-min {
  width: 100px;
}
input[type="date" i],
input[type="datetime-local" i] {
  color: #f9b115;
}
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  padding-left: 4px;
  margin: 0;
}

.actions .action {
  border-right: 1px solid #d8dbe0 !important;
  padding-right: 0.75rem !important;
}
</style>
